.projets {
    display: flex;
    justify-content: center;
}

.projets_container {

    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Deux colonnes égales */
    gap: 20px; /* Espace entre les box */
    margin: 200px auto 50px;

}

.projets_container_title{
    display: none;
}


.projets_box {
    position: relative;
    overflow: hidden;
    height: 300px;
    width: 100%;
    border-radius: 5px;
    -webkit-box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.7);
    box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.7);
    background-size: cover;
}

.projets_box::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.8s ease;
    border-radius: 8px;
}

.projets_box:hover::before {
    opacity: 0.5;
}

.projets_box_content {
    opacity: 0;
    color: #fff;
    margin: auto;
    text-align: center;
    width: 70%;

}

.projets_box_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.projets_box_content:hover{
    opacity: 1;
}


.projets_bouton {
    display: flex;
    align-items: center;
    width: 30%;
    margin: 20px auto;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;

}

.projets_bouton p {
    color: #0A0A0A;
    padding-right: 20px;

}


@media screen and (max-width: 900px){

    .projets_container{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 120px;
    }
    .projets_container_title{
        text-align: center;
        display: inline;
    }
    .projets_box_content {
        opacity: 1;
    }
    .projets_box::before{
        opacity: 0.5;
    }

    .projets_bouton{
        width: 81%;
    }
    .projets_box_content h3{
        font-size: 16px;
    }
}