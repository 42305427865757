.App {
    min-height: 100vh;
    width: 100%;
    background-color: #D4CFC3;
}

@media screen and (max-width: 900px) {
    .carousel-indicators{
        display: none !important;
    }
}