.contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0 50px 0 50px;
    background-color: #333333;
    color: white;
}

.contact_logo {
    width: 150px;
    height: 150px;
}

.contact_title {
    text-align: center;
    color: #333333;
}

.contact_content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 60%;
}

.contact h1 {
    text-align: center;
}

.contact_left {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto auto 30px;
}

.contact_left form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: #333333;
}

.contact_left form input {
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    color: #333333;

}

.contact_left form label {
    margin: 10px 0 10px 0;
}

.contact_left form textarea {
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    color: #333333;
    height: 120px;

}

.contact_left form button {
    width: 30%;
    margin: 30px auto;
    background-color: white;
    border-radius: 5px;
    border: none;
    height: 30px;
    cursor: pointer;
}

.contact_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    height: 50%;
}

.contact_right_first {
    display: flex;
    justify-content: center;

}

.contact_right_second {
    display: flex;
    justify-content: space-around;

}

.contact_right_second_rs {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: 120px auto 40px;
}

.contact_right_second_rs_content {
    display: flex;
    align-items: center;
    color: #333333;
    text-decoration: none;
    padding-left: 10px;
}

.contact_right_rs_content {
    display: flex;
}

.contact_right_second_rs_content a {
    text-decoration: none;
    color: #333333;
    padding-left: 10px;
}

.popup {
    background-color: red;
    border-radius: 10px;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    display: none;
    transition: top 0.3s ease; /* Animation de transition */
}

.popup.open {
    display: block;
}

.popup-message {
    margin-right: 40px;
    text-align: center;
    font-weight: bold;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.contact_right_second_title {
    margin-bottom: 20px !important;
    text-align: center;
}

.rs-icon {
    font-size: 30px;
}

@media screen and (max-width: 900px) {

}