.details{
    width: 100%;
    min-height: 100%;
}
.details_content{
    padding-top: 20vh;
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;
}
.details_content_text{
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-bottom: 40px;
}
.details_content_text h1 {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #333333;
}
.details_content_text p{
    text-align: center;
    color: #0A0A0A;
    margin-top: 40px !important;
}
.details_content_picture {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

}
.details_content_picture_img {
    width: 600px;
    margin-bottom: 20px;
    border-radius: 2px;

}


.containerPerso_text_box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px !important;
}
.containerPerso_icon{
    font-size: 20px;
    margin-left: 10px;
    color: var(--text-grey);
}
.containerPerso_text{
    color: var(--text-grey);

}

.containerPerso img {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.containerPerso img.normal {
    width: 300px; /* Taille normale de l'image */
}


.modalPerso {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modalPerso-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.zoomed{
    width: 70% !important;

}
@media screen and (max-width: 900px){
    .details_content{
        flex-direction: column;
    }
    .details_content_text{
        width: 100%;
        margin: auto;
    }
    .details_content_picture{
        width: 100%;
    }

    .containerPerso_text{
        font-size: 13px;
    }
}