
.accueil {
    display: flex;
    flex-direction: column;
    background-size: cover;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    animation: forwards 2s fadeIn;
}
.accueil_banner {
    display: flex;
    justify-content: center;
}
.accueil_content {
    display: flex;
    flex-direction: column;
}

.accueil_content_name {
    margin-bottom: 30px;
    text-align: center;
}

.accueil_bouton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
}

.accueil_bouton p {
    color: #0A0A0A;
    padding: 0 20px 0 0;
}

.arrow_icon {
    color: #0A0A0A;
    font-size: 20px;
    animation: infinite 2s fadeInLeft;

}



.vertical-carousel {

    height: 100vh;
    width: 100%;


}



.carousel-caption {
    margin: auto;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ease-in-out 1s fadeInUp;
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


