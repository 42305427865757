.navbar_perso {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    justify-content: space-around;
    box-sizing: initial;
}

.navbar_logo {
    color: white;
    width: 150px;
    height: 150px;
}

.navbar_list ul {
    display: flex;
    justify-content: space-around;
}

.navbar_list li {
    padding-right: 30px;
}

.navbar_list li p {
    font-weight: bold;
}
.navbar_contact_mobile{
    display: none;
}
.navbar_contact_desktop{
    display: inline;
}
.navbar_burger {
    display: none;
}

.overlay {
    display: none;
}

.overlay.active {
    display: none;
}

@media screen and (max-width: 900px) {

    .navbar_perso{
        justify-content: space-between;
    }
    .navbar_list {
        display: none;
    }
    .navbar_logo {
        width: 100px;
        height: 100px;
    }


    .navbar_burger {
        display: initial;
        padding-right: 40px;
        font-size: 25px;
    }

    .navbar_burger_show {
        background-color: #D4CFC3;
        position: fixed;
        top: 0;
        right: -200px;
        width: 200px;
        height: 100%;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
        transition: right 0.3s ease;
        z-index: 1001;

    }
    .navbar_burger_show_content{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 80%;
    }
    .navbar_burger_show_content ul {
        height: 50%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }
    .navbar_burger_rs_content{
        display: flex;
        justify-content: space-around;
    }
    .navbar_burger_show.active{
        right: 0;
    }

    .navbar_contact_mobile{
        display: inline;
    }
    .navbar_contact_desktop{
        display: none;
    }

    .overlay {
        display: initial;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;
        z-index: 999;
    }

    .overlay.active {
        display: initial;

        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        pointer-events: all;
    }
}


