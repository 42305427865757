.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0 50px 0 50px;
    background-color: #333333;
    color: white;
}
.footer_logo {
    width: 150px;
    height: 150px;
}
.footer_content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 60%;
}
.footer h1 {
    text-align: center;
}
.footer_left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 30px;
}
.footer_left form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: white;
}
.footer_left form input{
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    color: white;

}
.footer_left form label {
    margin: 10px 0 10px 0;
}
.footer_left form textarea{
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid white;
    color: white;
    height: 120px;

}
.footer_left form button{
    width: 30%;
    margin: 30px auto;
    background-color: white;
    border-radius: 5px;
    border: none;
    height: 30px;
    cursor: pointer;
}
.footer_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    height: 50%;
}

.footer_right_first{
    display: flex;
    justify-content: center;

}
.footer_right_second {
    display: flex;
    justify-content: space-around;

}
.footer_right_second_rs{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.footer_right_second_rs_content {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}
.footer_right_second_rs_content a {
    text-decoration: none;
    color: white;
    padding-left: 10px;
}

.popup {
    background-color: red;
    border-radius: 10px;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    display: none;
    transition: top 0.3s ease; /* Animation de transition */
}

.popup.open {
    display: block;
}

.popup-message {
    margin-right: 40px;
    text-align: center;
    font-weight: bold;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
}
.footer_right_second_title{
    margin-bottom: 20px !important;
}
.rs-icon{
    font-size: 30px;
}
@media screen and (max-width: 900px){
    .footer{
        display: none;
    }
}