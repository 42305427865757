:root {
    --bg-black: #0A0A0A;
    --bg-white: #fffff;
    --text-black: #00000;
    --text-white: white;
    --text-grey: #5F5F5F;
    --text-lightgrey: #8C8C8C;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-black);

}



p, h1, h2, h3, li, ul {
    color: var(--text-white);
    margin: 0!important;
    padding: 0;
    list-style: none;
}


a {
    text-decoration: none !important;
}

