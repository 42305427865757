.presentation{
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
}
.presentation_bio{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 60px;
}

.presentation_bio h1 {
    margin-bottom: 40px !important;
}

.presentation_bio p {
    color: #0A0A0A;
}
.presentation_bio h4 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px !important;
}

.presentation_photo_img{
    width: 500px;
    padding-left: 30px;
    border-radius: 5px;
}
.presentation_bio_list{
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}
.presentation_bio_list li {
    color: black;
    list-style: disc !important;
}
@media screen and (max-width: 900px) {

    .presentation{
        flex-direction: column-reverse;
    }
    .presentation_photo{
        display: flex;
        justify-content: center;
    }
    .presentation_photo_img{
        width: 70%;
        padding: 0;

        margin-top: 30px;
    }
    .presentation_bio{
        margin-bottom: 40px;
    }
    .presentation_bio h1 {
        text-align: center;
    }
    .presentation_bio p {
        font-size: 12px;
    }
}